import { lazy } from 'react'
import { AppRouteProps } from 'providers/RouteProvider'

const routes: AppRouteProps[] = [
  {
    exact: true,
    path: '/user/login',
    component: lazy(
      () => import(/* webpackChunkName: "LoginPage" */ 'pages/Login')
    ),
  },
  {
    exact: true,
    path: '/externalLogin',
    component: lazy(
      () =>
        import(/* webpackChunkName: "LoginPage" */ 'pages/Login/ExternalLogin')
    ),
  },
  {
    exact: true,
    path: '/video/call',
    component: lazy(
      () => import(/* webpackChunkName: "VideoCallPage" */ 'pages/VideoCall')
    ),
  },
  {
    exact: false,
    private: false,
    path: '/video/rooms',
    component: lazy(
      () => import(/* webpackChunkName: "MediaPage" */ 'pages/Media')
    ),
  },
  {
    exact: true,
    path: '/video/end-call',
    component: lazy(
      () => import(/* webpackChunkName: "MediaPage" */ 'pages/VideoEnd')
    ),
  },

  {
    exact: true,
    private: true,
    accessPath: 'dashboard',
    path: '/dashboard',
    component: lazy(
      () => import(/* webpackChunkName: "DashboardPage" */ 'pages/Dashboard')
    ),
  },
  {
    exact: true,
    private: true,
    accessPath: 'scorecard',
    path: '/scoreboard',
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "SupplierScorecard" */ 'pages/SupplierScorecard'
        )
    ),
  },
  {
    exact: true,
    //private: true,
    path: '/booking',
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "SupplierScorecard" */ 'pages/BookingService'
        )
    ),
  },
  {
    exact: true,
    private: true,
    path: '/communication',
    accessPath: 'communicationtasks',
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "CommunicationPage" */ 'pages/Communication'
        )
    ),
  },
  {
    exact: true,
    private: true,
    accessPath: 'claims',
    path: '/claims',
    component: lazy(
      () => import(/* webpackChunkName: "ClaimListPage" */ 'pages/ClaimList')
    ),
  },
  {
    exact: true,
    private: true,
    accessPath: 'specialist',
    path: '/specialist',
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "SpecialistRequestListPage" */ 'pages/SpecialistRequestList'
        )
    ),
  },
  {
    exact: false,
    private: true,
    // accessPath: 'claims',
    path: '/claim/:claimId',
    component: lazy(
      () => import(/* webpackChunkName: "ClaimPage" */ 'pages/Claim')
    ),
  },
  {
    exact: false,
    private: true,
    accessPath: 'claims',
    path: '/add-claim',
    component: lazy(
      () => import(/* webpackChunkName: "AddClaimPage" */ 'pages/ClaimAdd')
    ),
  },
  {
    exact: true,
    path: '/survey',
    component: lazy(
      () => import(/* webpackChunkName: "SmsSurvey" */ 'pages/Survey')
    ),
  },
  {
    exact: false,
    private: true,
    path: '/settings',
    component: lazy(
      () => import(/* webpackChunkName: "SettingsPage" */ 'pages/Settings')
    ),
  },

  {
    exact: true,
    path: '*',
    component: lazy(
      () => import(/* webpackChunkName: "NotFoundPage" */ 'pages/NotFound')
    ),
  },
]
export default routes
