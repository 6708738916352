export const API_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT || 'https://api-dev.endataclaims.com'

export const WS_API_ENDPOINT =
  process.env.REACT_APP_WS_API_ENDPOINT || 'https://api-ws-dev.endataclaims.com'

export const WS_SUBSCRIPTION_ENDPOINT =
  process.env.REACT_APP_WS_SUBSCRIPTION_ENDPOINT ||
  'wss://api-ws-dev.endataclaims.com/graphql'

export const COMPANY_RESOURCE =
  process.env.REACT_APP_COMPANY_RESOURCE ||
  'https://companyresources.blob.core.windows.net/image'

export const REDIRECT_URL =
  process.env.REACT_APP_REDIRECT_URL || 'https://endata-dev2.endataclaims.com'

export const DEFAULT_REDIRECT_URL =
  process.env.REACT_APP_DEFAULT_REDIRECT_URL ||
  'https://login-test.endataclaims.com/'

export const TURN_SERVER_URL =
  process.env.REACT_APP_TURN_SERVER_URL ||
  'turn:coturn-uat.endataclaims.com:3478?transport=tcp'
export const TURN_SERVER_USERNAME =
  process.env.REACT_APP_TURN_SERVER_USERNAME || 'endata'
export const TURN_SERVER_CREDENTIAL =
  process.env.REACT_APP_TURN_SERVER_CREDENTIAL || '567tyughj'

export const ICE_SERVERS: any[] = []
if (TURN_SERVER_URL) {
  ICE_SERVERS.push({
    urls: TURN_SERVER_URL,
    username: TURN_SERVER_USERNAME,
    credential: TURN_SERVER_CREDENTIAL,
  })
}
