import React from 'react'
// import { useLocation } from 'react-router-dom'
import { useRelayEnvironment } from 'react-relay'
import { IconButton, Menu, MenuItem, makeStyles, Icon } from '@material-ui/core'
// import MenuIcon from '@material-ui/icons/Menu'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import {
  Settings,
  // DirectionsRun
} from '@material-ui/icons'
import { useHistory, Link } from 'react-router-dom'
import { commitAppMeta } from 'providers/RelayProvider/commitAppMeta'
// import { type } from 'os'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'
import { REDIRECT_URL } from 'configs'

export type SystemMenuProps = {
  menuData: any
  disposeNavbarQuery: any
}

const SystemMenu: React.FC<SystemMenuProps> = ({
  disposeNavbarQuery,
  ...props
}) => {
  const classes = useStyles()
  // const currentPath = useLocation().pathname
  // const currentQueryString = useLocation().search
  const { push } = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event: any) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const environment = useRelayEnvironment()

  const handleLogout = () => {
    handleClose()
    localStorage.clear()
    commitAppMeta(environment, { accessToken: null, refreshToken: null })
    disposeNavbarQuery()
    //push(`${process.env.REACT_APP_DEFAULT_REDIRECT_URL}`)
    if (process.env.REACT_APP_DEFAULT_REDIRECT_URL) {
      window.location.href = `${process.env.REACT_APP_DEFAULT_REDIRECT_URL}`
    } else {
      push('/user/login')
    }
  }

  const menuIcon = useFragment(
    graphql`
      fragment SystemMenuPropsData on Query {
        currentUser {
          menuItems {
            config {
              icon
            }
          }
          name
        }
      }
    `,
    props.menuData
  )

  const icon = menuIcon?.currentUser?.menuItems?.config?.icon

  return (
    <>
      {REDIRECT_URL && (
        <iframe
          src={`${REDIRECT_URL}/externalLogin`}
          id="portal"
          width={0}
          height={0}
          title="classicPortal"
          style={{ visibility: 'hidden' }}
        />
      )}
      <IconButton
        className={anchorEl ? classes.iconOnOpen : classes.icon}
        onClick={handleClick}
        color="inherit"
      >
        {<Icon>{icon}</Icon>}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
        classes={{
          paper: classes.menuPaper,
        }}
      >
        <div className={classes.welcomeInfo}>
          Welcome, {menuIcon?.currentUser?.name}
        </div>
        <MenuItem component={Link} to="/settings/userDetail">
          <Settings className={classes.itemIcon} />
          <div className={classes.itemText}>Settings</div>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            const portal = document.getElementById('portal')
            const refreshToken = localStorage.getItem('REFRESH_TOKEN')
            const data = JSON.stringify({ refreshToken })
            //@ts-ignore
            portal.contentWindow.postMessage(data, '*')
            const [, page, claimString, tab] = currentPath.split('/')
            const currentPortfolio = currentQueryString
              ?.substring(1)
              .toLowerCase()
            const claimId =
              claimString && window.atob(claimString).split(':')[1]
            window.setTimeout(() => {
              window.open(
                `${REDIRECT_URL}/${page}${
                  tab
                    ? `/${tab === 'invoices' ? 'fees' : tab}?id=${claimId}`
                    : ''
                }${currentPortfolio ? `&${currentPortfolio}` : ''}`
              )
            }, 1000)
          }}
        >
          <DirectionsRun className={classes.itemIcon} />
          <div className={classes.itemText}>To Classic</div>
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <PowerSettingsNewIcon className={classes.itemIcon} />
          <div className={classes.itemText}>Logout</div>
        </MenuItem>
      </Menu>
    </>
  )
}
export default SystemMenu

const useStyles = makeStyles(theme => ({
  menu: {
    '& div[role="document"]': {
      top: '50px !important',
    },
  },
  menuPaper: {
    borderRadius: theme.spacing(0.6),
  },
  icon: {},
  iconOnOpen: {
    background: 'rgba(0,0,0, 0.3)',
  },
  itemIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
    opacity: 0.65,
  },
  itemText: {
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(2),
  },
  welcomeInfo: {
    padding: '15px',
    fontWeight: 600,
    borderBottom: '2px grey solid',
    color: theme.palette.text.hint,
  },
}))
